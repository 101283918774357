<template>
    <div>
        <div class="header_wrap position-relative">
            <Header class="header flex-between-center p-3 py-2 position-fixed top-0 start-0 w-100">
                <div class="fs-px-15">
                    <span class="text-yellow-1" @click="$router.push('/mypage')">
                        Back
                    </span>
                </div>
                <div class="tit">
                    <span class="d-block text-yellow-1 fs-5 fw-bold text-truncate">Edit Profile</span>
                </div>
                <div class="fs-px-15">
                    <span class="text-yellow-1 border-0 ms-2" @click="Save()">
                        Save
                    </span>
                </div>
            </Header>
        </div>
        <div class="content_wrap">
            <div class="flex-center-center flex-column mb-4">
                <img src="@/assets/img/user-icon.svg" alt="profile" width="150" v-if="profile_img == ''">
                <img :src="profile_img" alt="profile" width="150" height="150" class="rounded-circle object-fit-cover" v-else>

                <label class="btn btn-sm text-white border mt-3 flex-between-center">
                    <input type="file" hidden @change="UploadFile" accept="image/*">
                    <img src="@/assets/img/layout_icon/camera.svg" width="20"> <span class="ms-1 fs-px-12">Upload</span>
                </label>
            </div>

            <div class="border-bottom border-yellow-1">
                <p class="text-white mb-2">Edit Nickname</p>
                <div class="flex-between-center">
                    <div class="rounded-1 h-px-48 bg-white flex-between-center flex-grow-1">
                        <input type="text" class="form-control border-0 h-100" placeholder="Nickname" maxlength="15" v-model="nickname" @change="ChangeNick">
                        <div class="pe-2 w-px-48">
                            <img src="@/assets/img/layout_icon/x_circle.svg" alt="remove form" width="28" v-show="nickname!=''" @click="nickname=''">
                        </div>
                    </div>
                    <button class="h-px-48 border-0 rounded-1 bg-yellow-1 btn flex-center-center w-px-120 ms-2" @click="CheckNick()">
                        <small class="fs-px-12 lh-1">Check<br>Availability</small>
                    </button>
                </div>

                <ul class="text-burgundy-4 py-3">
                    <li class="fs-px-12 d-flex mb-2">
                        <span class="me-2">·</span>
                        <span>Minimum 2 characters, maximum 15 characters</span>
                    </li>
                    <li class="fs-px-12 d-flex mb-2">
                        <span class="me-2">·</span>
                        <span>No requirement for uppercase, lowercase letters</span>
                    </li>
                    <li class="fs-px-12 d-flex mb-2">
                        <span class="me-2">·</span>
                        <span>Can not use special characters !@#$%^&*()-</span>
                    </li>
                </ul>
            </div>

            <div class="text-end mt-3">
                <div class="flex-between-center text-white">
                    <span>UID</span>
                    <span>{{uid}}</span>
                </div>
                <span class="text-burgundy-4 fs-px-12">* UID can not be modified</span>
            </div>
        </div>
        
        <Footer></Footer>

    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Footer from '@/components/common/Footer.vue'
const CryptoJS = require("crypto-js");
import AWS from 'aws-sdk';

export default {
    components:{
        SubHeader, Footer
    },
    data(){
        return{
            nickname: '',
            profile_img: this.$store.state.profile,
            uid: '',
            Bucket : process.env.VUE_APP_AWSBUCKETNAME,
            region : process.env.VUE_APP_AWSBUCKETREGION,
			IdentityPoolId: process.env.VUE_APP_AWSPOOLID,
			AWSURL: process.env.VUE_APP_AWSURL,
            // front ui
            nickname_valid :'',
            selected_file: '',

        }
    },
    mounted(){
        this.GetMyProfile()
    },
    methods:{
        ChangeNick(){
            this.nickname_valid = false;
        },
        GetMyProfile(){
            
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();



            this.$http.post('/member/mypage/GetProfile',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.profile_img = body.info.profile;
                            this.uid = body.info.code;
                            this.nickname = body.info.nick_name;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        ProfileChange(){
            const nickname = this.nickname;
            const profile_img = this.profile_img;
            const body = {nickname,profile_img};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/member/mypage/EditMemberProfile',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const info = {profile : profile_img};
                            this.$store.dispatch('CHANGEPROFILE',{info}).then(
                                ()=>{
                                    this.$alert("Saved").then(()=>{
                                        this.$router.push('/mypage')
                                    })
                                }
                            )
                            
                        }
                    }
                }   
            )

        },
        
        CheckNick(){
            const regex = /^[\p{L}\d]{2,15}$/u;

            // 0603 공백일 때 조건 추가
            
            if (!this.nickname || this.nickname.trim() === "") {
                this.nickname_valid = false;
                this.$pp({
                    msg: 'Nickname can not be blank!', 
                    is_confirm: false, 
                    auto_hide: true
                });

                return false;
            }
            
            if (this.nickname.match(regex)) {
                this.nickname_valid = true;
            } else {
                this.nickname_valid = false;
                this.$pp({
                    msg: 'nickname includes unsupported character', 
                    is_confirm: false, 
                    auto_hide:true
                })

                return false
            }
            

            const nickname = this.nickname;
            const body = {nickname};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/member/mypage/CheckNickName',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            
                            this.$pp({
                                msg: 'Available', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                        }else if(res.data.code =="100"){
                            this.nickname_valid = false;
                            this.$pp({
                                msg: 'Unavailable', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                        }
                    }
                }   
            )

        },
        
        Save(){
            let result = true;

            if( !this.nickname_valid ){
                this.$pp({
                    msg: 'Check Nickname availability first', 
                    is_confirm: false, 
                    auto_hide:true
                })
                result = false;
                return;
            }
            
            if( result == true ){
                this.ProfileChange();
                
            }
        },
        UploadFile(e){
            const files = e.target.files;

            const file = files[0];
            const date = new Date();
            const timestamp = date.getTime();
            const fileName = timestamp+file.name;

            if (file && file.type.includes('image')) {
                this.profile_img = file;
                AWS.config.update({region: this.region,credentials: new AWS.CognitoIdentityCredentials({IdentityPoolId: this.IdentityPoolId})});
                var s3 = new AWS.S3({apiVersion: "2006-03-01",params: { Bucket: this.Bucket}});
                s3.upload({Key: fileName, Body : file, ACL:'public-read','ContentType':`${file.type}`}, (err)=>{
                    if(err == null){
                    const path = this.AWSURL + fileName;
                    
                    this.profile_img = path;
                    }
                })
            } else {
                this.selected_file = null;
                this.profile_img = null;
            }
			
			
			

            
		},
        
    }

}
</script>
<style lang="scss" scoped>
    .header_wrap {
        padding-bottom: 55px;
        z-index: 100;

        .header {
            height: 55px;
            background-color: #36001f;

            .tit{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 70%;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
            }
        }
    }
</style>