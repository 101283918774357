<template>
    <div class="">

        <SubHeader :title="'Delete Account'" :link="'/mypage/personal-info-setting'"/>
        <div class="content_wrap text-white">
            <div>
                <p class="mb-3">Are you sure you want to delete your OCB account?</p>

                <p class="mb-3">
                    If you’re having problems, please contact <span class="text-yellow-1 text-decoration-underline fw-bold" @click="$router.push('/mypage/inquiry')">Customer Service.</span>
                </p>

                <p class="mb-3">Please be sure to check your balance before deleting your account. </p>

                <p class="fw-bold mb-3">If you log-in with deleted account within 14 days after account delete, your account will be restored, otherwise your account will be permanently deleted.</p>

                <p class="fw-bold mb-3">Once account is permanetly deleted your asset and account can not be restored.</p>
            </div>

            <div class="mt-5">
                <button class="py-3 border-0 rounded-1 bg-red w-100 flex-center-center fw-bold fs-px-13 mb-2 text-white btn" @click="DeleteAccount()">
                    DELETE ACCOUNT
                </button>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Footer from '@/components/common/Footer.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        SubHeader, Footer
    },
    methods:{
        
        DeleteAccount(){
            this.$confirm("Delete your account?").then((result)=>{
                if(result.is_ok){
                    const body = {};
                    const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

                    this.$http.post('/member/mypage/DeleteMember',{req}).then(
                        (res) =>  {
                            if(res.status == 200){
                                this.loading = false;
                                if(res.data.code =="200"){
                                    this.$alert("Delete account success!").then(()=>{
                                        this.$store.dispatch('logout').then(
                                            ()=>{
                                                location.href = `/signin`;
                                            }
                                            
                                        )
                                    })
                                    
                                }else if(res.data.code =="9999"){
                                    this.$store.dispatch('logout').then(
                                        ()=>{
                                            location.href = `/signin`;
                                        }
                                    );
                                }
                            }
                        }   
                    )
                    
                }
            })
        },
    }
}
</script>

<style>

</style>