<template>
    <div>
        <SubHeader :title="'Inquiry'" :link="'/mypage/inquiry'"/>
        <div class="content_wrap">
            <div>
                <p class="text-white mb-2">title</p>
                <div class="rounded-1 h-px-48 bg-white flex-between-center">
                    <input type="text" class="form-control border-0 h-100" placeholder="" v-model="title">
                </div>
            </div>

            <div class="py-4">
                <p class="text-white mb-2">Inquiry</p>
                <textarea class="rounded-2 w-100 p-2" rows="8" v-model="memo"></textarea>
            </div>

            <div class="flex-between-center">
                <button class="py-3 border-0 btn rounded-1 bg-yellow-1 w-100 flex-center-center fw-bold" @click="Submit()">
                    Submit Inquiry
                </button>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Footer from '@/components/common/Footer.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        SubHeader, Footer
    },
    data(){
        return{

            title: '',
            memo: '',

            // front ui
        }
    },
    mounted(){
        this.GetProfileInfo();
    },
    methods:{
        GetProfileInfo(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/mypage/GetProfile',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.email = body.info.email;
                            
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        Submit(){
            const title = this.title;
            const memo = this.memo;
            const body = {memo,title};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/mypage/AddInquiry',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$alert("Inqury sent!").then(()=>{
                                this.$router.push("/mypage/inquiry")
                            })
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
            
        }
    }

}
</script>