<template>
    <div class="deal">
        <SubHeader :title="''" :share="`${url}/deal/detail/${code}`" right_home_btn/>
        <div class="px-3">
            <div class="text-white flex-between-center fs-px-11 mb-2">
                Seller’s Deal<span class="text-yellow-1">UNIQUE DEAL NUMBER : {{code}}</span>
            </div>
            <div class="bg-white text-dark rounded-3 mb-3 overflow-hidden" v-if="show">
                <div class="d-flex justify-content-evenly p-3">
                    <img :src="require(`@/assets/img/icon/color/${info.coin.toLowerCase()}.svg`)" alt="coin" class="rounded-circle" height="60" >
                    <div class="d-flex flex-column justify-content-center align-items-center">
                        <p><b class="fs-6">{{ info.price.toLocaleString() }}</b> <small class="uppercase">{{info.coin}}</small></p>
                        <small class="text-muted fs-px-12">(= ${{info.total_price.toLocaleString()}})</small>
                    </div>
                </div>

                <div class="open p-3 pt-0" v-if="info.status">
                    <hr>
                    <div>
                        <p class="text-center small">Remaining time until expiration</p>
                        <ul class="flex-center-center gap-4 fw-bold py-2">
                            <li>{{ countdown.days  }} DAYS</li>
                            <li>{{ countdown.hours  }} HOURS</li>
                            <li>{{ countdown.minutes  }} M</li>
                            <li>{{ countdown.seconds  }} S</li>
                        </ul>
                    </div>
                </div>
                <div class="closed mt-3 p-3 bg-burgundy-5" v-else>
                    <h5 class="fw-bold text-center py-3" v-if="info.status_code =='C'" v-html="info.close_message"></h5>
                    <h5 class="fw-bold text-center py-3" v-else>{{closedMsg}}</h5>
                </div>
            </div>

            <div class="seller d-flex fs-px-12 mb-2">
                <div @click="GoUserProfile(info.seller_code,info.m_code)">
                    <b-avatar variant="info" :src="info.profile" class="avatar" :class="{'bg-yellow-1':info.profile===''||!info.profile}" height="40"></b-avatar>
                </div>
                <div class="d-flex flex-column ms-3">
                    <small>SELLER</small>{{info.owner}}
                </div>
            </div>

            <hr class="border-yellow-1">
            <div class="item-info">
                <h6 class="text-truncate mb-2">{{info.title}}</h6>
                <p class="small fw-light fs-px-12 mb-3">{{info.memo}}</p>
                <ul class="fs-px-11">
                    <li class="flex-between-center mb-1">
                        <span class="text-burgundy-4">Ticket’s Token Type</span>
                        <span>{{info.deal_coin}}</span>
                    </li>
                    <li class="flex-between-center mb-1">
                        <span class="text-burgundy-4">Ticket Price</span>
                        <span>{{ info.d_price }} {{info.deal_coin}} / TICKET</span>
                    </li>
                    <li class="flex-between-center mb-1">
                        <span class="text-burgundy-4">Total ticket quantity</span>
                        <span>{{info.total}}</span>
                    </li>
                </ul>
            </div>
            <hr class="border-yellow-1" v-if="winner.status">

            <div v-if="info.status_code =='E'">
                <div class="winner_board fs-px-11 mb-3 bg-yellow-4 p-3 rounded-3" style="margin-top: 5rem;" v-if="winner.status">
                    <div class="winner">
                        <img src="@/assets/img/winner_top.svg" alt="winner" height="102" class="winner_img position-absolute start-50 translate-middle">
                        <div @click="GoUserProfile(winner.member_code,winner.m_code)">
                            <img src="@/assets/img/user-icon.svg" class="winner_avatar avatar position-absolute start-50" v-if="winner.profile===''||!winner.profile">
                            <b-avatar v-else :src="winner.profile" class="winner_avatar avatar position-absolute start-50" ></b-avatar>
                        </div>
                        <img src="@/assets/img/winner_bottom.svg" alt="winner" height="35" class="winner_label position-absolute start-50 translate-middle">
                    </div>
                    <div class="flex-between-center mb-1" v-if="winner.nick_name !=''">
                        <span class="text-gray-300">Winner/UID</span>
                        <span class="text-dark fw-bold">{{winner.nick_name}}/{{winner.member_code}}</span>
                    </div>
                    <div class="flex-between-center mb-1"  v-if="winner.ticket_num !=''">
                        <span class="text-gray-300">Winning Ticket Number</span>
                        <span class="text-dark fw-bold text-end">{{ winner.ticket_num}}</span>
                    </div>
                    <div class="flex-between-center flex-wrap mb-1" v-if="winner.ticket_code !=''">
                        <span class="text-gray-300 text-nowrap">Deal serial number</span>
                        <span class="text-dark fw-bold text-end ms-auto">{{winner.ticket_code}}</span>
                    </div>
                    <div class="flex-between-center mb-1" v-if="winner.ticket_quantity !=''">
                        <span class="text-gray-300">Number of tickets purchased</span>
                        <span class="text-dark fw-bold text-end">{{winner.ticket_quantity }}</span>
                    </div>
                    <div class="flex-between-center flex-wrap mb-3"  v-if="winner.result_time !=''">
                        <span class="text-gray-300 text-nowrap">Result Announcement Time</span>
                        <span class="text-dark fw-bold text-end ms-auto">{{winner.result_time }}</span>
                    </div>
                    <button class="btn bg-yellow-1 text-dark w-100 fw-bold fs-px-14 h-px-42" @click="$router.push(`/deal/detail/${info.code}/winner`)">See Details</button>
                </div>
            </div>

            <div v-if="info.status_code =='Y'">
                <div class="position-relative mb-3 bg-burgundy-5 p-3 rounded-3 pt-4" style="margin-top: 3rem;" v-if="info.total ==info.count">
                    <div class="winner text-center text-dark">
                        <img src="@/assets/img/winner_bottom.svg" alt="winner" height="45" class="winner_label position-absolute top-0 start-50 translate-middle">
                        <p class="py-3">Winner drawing in progress<br>Remaining time</p>
                        <div class="open p-3 pt-0">
                            <hr>
                            <div>
                                <ul class="flex-center-center gap-4 fw-bold py-2">
                                    <li>{{ end_countdown.minutes  }} M</li>
                                    <li>{{ end_countdown.seconds  }} S</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex-between-center small mb-1">
                    <span class="fw-bold">Current Progress</span>
                    <span><b>{{ Math.floor((info.count / info.total) * 1000) / 10 }}% </b>({{info.count}}/{{info.total}})</span>
                </div>

                <div class="progress bg-white">
                    <div class="progress-bar bg-danger" :style="{width:`${parseInt((info.count/info.total)*100)}%`}"></div>
                </div>
            </div>
            <hr class="border-yellow-1">

            <div class="buyer-list">
                <p class="mb-3 fw-bold">Buyer List</p>
                <div class="bg-white text-dark rounded-3 p-3 mb-3" v-if="my_deal.status">
                    <ul class="fs-px-11">
                        <li class="flex-between-center mb-1">
                            <span class="text-muted">My Purchase</span>
                            <span class="fw-bold">{{my_deal.total}} Tickets</span>
                        </li>
                        <li class="flex-between-center mb-1">
                            <span class="text-muted">Total Purchase Amount</span>
                            <span class="fw-bold">{{my_deal.total_count}} {{my_deal.deal_coin}} (= $ {{CheckPrice(my_deal.total_price)}})</span>
                        </li>
                        <li class="flex-between-center mb-1">
                            <span class="text-muted">Chance of Winning</span>
                            <span class="fw-bold">{{my_deal.profit.toLocaleString()}} %</span>
                        </li>
                        <li class="flex-between-center mb-1">
                            <span class="text-muted">Estimated Profit</span>
                            <span class="fw-bold">{{my_deal.benifit}} %</span>
                        </li>
                    </ul>
                </div>
                <ul>
                    <li class="d-flex mb-3" v-for="(item,index) in list" :key="index">
                        <div @click="GoUserProfile(item.uid,item.m_code)">
                            <b-avatar :src="item.profile" class="avatar" :class="{'bg-yellow-1':item.profile===''||!item.profile}" height="40"></b-avatar>
                        </div>
                        <div class="d-flex flex-column ms-2 fs-px-12 w-100">
                            <div class="flex-between-center">
                                <span class="fs-px-13">{{ item.nick_name }} <small> purchased {{item.total}} tickets</small></span>
                                <span class="badge bg-burgundy-4 text-burgundy-0 py-0" @click="DetailBuyer(item)"><i class="fas fa-ellipsis-h"></i></span>
                            </div>
                            <span class="text-burgundy-4">LATEST PURCHASE TIME : {{item.time}}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div v-if="info.status">
            <div class="deal-footer bg-yellow-1" v-if="CheckDealInfo() =='N'">
                <span class="ticket bg-burgundy-1 fs-px-12 text-white px-3 py-3 fw-bold" @click="modalShow = !modalShow">Buy Tickets</span>
                <div class="text-center fw-bold text-burgundy-1 "><i class="d-block fa-heart fs-3" :class="{'fas':my_deal.c_wish,'far':!my_deal.c_wish}" @click="wishList"></i>WISH LIST</div>
            </div>
            <div class="deal-footer bg-yellow-1" v-if="CheckDealInfo() =='Y'">
                <span class="ticket bg-burgundy-1 fs-px-11 text-white px-3 py-3 fw-bold" @click="modalShow = !modalShow">BUY MORE TICKETS</span>
                <button class="w-px-120 py-2 fs-px-11 fw-bold btn btn-dark rounded-0" @click="refundShow= !refundShow">REFUND<br>TICKET</button>
            </div>

            <div class="deal-footer bg-yellow-1" v-if="CheckDealInfo() =='M'">
                <div class="d-flex w-100 px-3">
                    <button class="py-3 fs-px-12 btn btn-transparent w-50" @click="CheckCancel()">CANCEL DEAL</button>
                    <button class="py-2 fs-px-12 fw-bold btn btn-dark rounded-0 w-50" @click="EditConfirm()">EDIT INTRODUCTION</button>
                </div>
            </div>
        </div>

        <PurchasePopup :show="modalShow" @close="modalClose" :buyTF="my_deal" :info="info" v-if="modalShow"/>
        <RefundPopup :show="refundShow" @close="refundShow=false" :buyTF="my_deal" :info="info"  v-if="refundShow"/>
        
        <b-modal v-model="detailModal" centered hideHeader hideFooter >
            <div>
                <h6 class="text-center mb-2 py-2">
                    <i class="fal fa-times fs-5 text-yellow-1 position-absolute end-0 me-3" @click="detailModal= !detailModal"></i>View Details
                </h6>
                <div class="fs-px-12">
                    <div class="flex-between-center mb-3">
                        <span>Deal Number</span>
                        <span class="fw-bold">{{code}}</span>
                    </div>
                    <div class="flex-between-center mb-3">
                        <span>Nickname</span>
                        <span class="fw-bold">{{d_nickname}}</span>
                    </div>
                    <div class="flex-between-center mb-3">
                        <span>UID</span>
                        <span class="fw-bold">{{d_uid}}</span>
                    </div>
                    <div class="flex-between-center mb-3">
                        <span>Purchased ticket quantity</span>
                        <span class="fw-bold">{{d_total}}</span>
                    </div>
                    <div class="flex-column mb-1">
                        <span>Purchased ticket numbers</span>
                        <ul class="ticket_num_box">
                            <li class="badge bg-yellow-4 text-black" v-for="(item, index) in d_tickets" :key="index">{{item}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </b-modal>
<!-- 
        <b-modal v-model="cancelModal" centered hideHeader >
            <h6 class="text-center mb-3 py-2"><i class="fal fa-exclamation-triangle text-danger fa-3x"></i></h6>
            <p class="text-center mb-4 fs-px-14">{{dealer_cancel_fee}}% cancellation fee(out of deal’s total amount) will be charged to Seller. <br>Are you sure you want to cancel the deal?</p>
            <div class="fs-px-12">
                <div class="flex-between-center mb-3">
                    <span class="text-muted">Deal’s Total Amount</span>
                    <span>{{info.price}} {{info.coin}}</span>
                </div>
                <div class="flex-between-center mb-3">
                    <span class="text-muted">Cancellation Fee</span>
                    <span>{{dealer_cancel_fee_price}} {{info.coin}}</span>
                </div>
                <hr>
                <div class="flex-between-center fw-bold">
                    <span>Total Refund Amount</span>
                    <span class="fw-bold">{{dealer_cancel_total}} {{info.coin}}</span>
                </div>
            </div>
            <template #modal-footer>
                <div class="d-flex w-100 px-3">
                    <button class="py-3 fs-px-12 btn btn-transparent w-50" @click="cancelModal=!cancelModal">Retreat<br>Cancellation</button>
                    <button class="py-2 fs-px-12 fw-bold btn btn-danger w-50" @click="cancelCheckModal=!cancelCheckModal,cancelModal=!cancelModal">CONFIRM<br>CANCELLATION</button>
                </div>
            </template>
        </b-modal> -->
        <b-modal v-model="cancelCheckModal" centered hideHeader content-class="rounded-2" >
            <p class="text-center mb-4 fs-px-14 fw-bold">If you cancel a transaction early<br>A fee will apply.</p>
            <div class="fs-px-12">
                <div class="flex-between-center mb-3">
                    <span class="text-muted">Cancellation fee rate</span>
                    <span class="fw-bold">{{dealer_cancel_fee}} %</span>
                </div>
                <div class="flex-between-center mb-3">
                    <span class="text-muted">Cancellation fee amount</span>
                    <span class="fw-bold">{{dealer_cancel_fee_price}} {{info.coin}}</span>
                </div>
                <div class="flex-between-center">
                    <span>balance in my wallet</span>
                    <span class="fw-bold">{{user_balance}} {{info.coin}}</span>
                </div>
            </div>
            <p class="text-center text-danger fs-px-12 pt-3">Are you sure you want to cancel?</p>
            <template #modal-footer>
                <div class="d-flex w-100 px-3">
                    <button class="py-3 fs-px-12 btn btn-transparent w-50" @click="cancelCheckModal=!cancelCheckModal">Back</button>
                    <button class="py-2 fs-px-12 fw-bold btn bg-yellow-1 w-50" @click="CancelDeal()">Confirm</button>
                </div>
            </template>
        </b-modal>
        <div class="basic_pp bottom_up text-dark" v-show="edit_intro">
            <div class="dimm bg-body-i bg-opacity-50" @click="edit_intro=false"></div>
            <div class="content bg-yellow-1 rounded-0 edit_content w-100">
                <p class="text-center fw-bold">EDIT INTRODUCTION</p>
                <div class="form-group mb-3">
                    <label for="" class="mb-2">Title</label>
                    <input type="text" class="form-control" v-model="e_title" @keyup="CheckTitle()">
                    <p v-show="title_error" class="text-danger pt-3">Exceeds character limit.</p>
                </div>
                <div class="form-group mb-3">
                    <label for="" class="mb-2">Description</label>
                    <textarea class="form-control" name="" id="" cols="30" rows="10" v-model="e_memo"  maxlength="100"  @input="e_memo.length>=100?e_memo_error=true:e_memo_error=false"></textarea>
                    <p v-show="e_memo_error" class="text-danger pt-3">Exceeds character limit.</p>
                </div>
                <div class="d-flex w-100">
                    <button class="py-3 fs-px-12 btn btn-transparent w-50" @click="CloseEditInfo()">CANCEL</button>
                    <button class="py-2 fs-px-12 fw-bold btn btn-dark rounded-0 w-50" @click="EditInfo()">CONFIRM</button>
                </div>
            </div>
        </div>
        <Loading v-if="loading"/>
    </div>
</template>

<script>
import { BAvatar, BModal } from 'bootstrap-vue'
import PurchasePopup from '@/components/deal/PurchaseModal.vue'
import RefundPopup from '@/components/deal/RefundModal.vue'
import SubHeader from '@/components/common/SubHeader.vue'
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');
import Loading from '@/components/common/Loading.vue'

export default {
    name: 'Home',
    components: {
        SubHeader, BAvatar, BModal, PurchasePopup, RefundPopup,Loading
    },
    created(){
        this.GetDealDetail();
        this.GetDealDetailBuyer()
        if(this.login){
            this.GetCancelFee();
            this.GetMyInfo();
        }
    },
    methods:{
        CheckTitle(){
            if(this.e_title.length>=20){
                this.title_error=true;
                this.e_title = this.e_title.substring(0,20);
            }else{
                this.title_error=false;
            }
        },
        CheckPrice(price){
            const amount = price*1;
            const a = amount.toFixed(2).toLocaleString();
            return a
        },
        EditConfirm(){
            this.e_title = this.info.title;
            this.e_memo = this.info.memo;
            this.edit_intro = true;
        },
        CloseEditInfo(){
            this.edit_intro=false;
        },
        CheckDealInfo(){
            const deal_owner = this.deal_owner;
            const deal_status = this.my_deal.status;

            if(deal_owner =='Y'){
                return 'M'
            }else if(deal_owner =='N'){
                if(deal_status){
                    return 'Y';
                }else{
                    return 'N';
                }
            }
        },
        CheckCancel(){
            const price = this.info.price;
            const dealer_cancel_fee = this.dealer_cancel_fee
            const fee = exactMath.mul(price,dealer_cancel_fee,0.01);
            this.dealer_cancel_fee_price = fee;
            const total = exactMath.sub(price,fee);
            this.dealer_cancel_total = total;
            this.GetMyBalance();
            this.cancelCheckModal=true;
        },
        EditInfo(){
            const code = this.code;
            const memo = this.e_memo;
            const title = this.e_title;
            const body = {code,memo,title};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/member/deal/EditInfo',{req}).then(
                (res) =>  {
                if(res.status == 200){
                    this.loading = false;
                if(res.data.code =="200"){
                  this.$pp({
                      msg:'Edit Complete',
                      auto_hide:true
                  })
                  this.CloseEditInfo();
                  this.GetDealDetail();
                  this.GetDealDetailBuyer()
                  

                  if(this.login){
                    this.GetMyInfo();
                  }
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
    )
  },
  DetailBuyer(item){
    this.d_nickname = item.nick_name;
    this.d_tickets = item.ticket.split(',');
    this.d_uid = item.uid;
    this.d_total = item.total;
    this.detailModal=!this.detailModal;
  },
  GetMyInfo(){
    const code = this.code;
    
    const body = {code};

    const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

    this.$http.post('/member/deal/GetMyInfo',{req}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    
                    this.my_deal = body.info;

                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
    )
  },
  GetDealDetail(){
    const code = this.code;
    
    const body = {code};

    const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

    this.$http.post('/member/deal/GetDealDetail',{req}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    this.info = body.info;
                    this.deal_owner = body.info.deal_owner;
                    this.show = true;
                    this.winner = body.winner;
                    this.last_time = body.last_time;
                    
                    this.expiryDate = body.info.close_time;

                    if(this.info.status_code =='C'){
                      this.info.status = false;  
                      this.closedMsg='<small>Invalid Deal<br>Deal has been cancelled by Seller</small>'
                      return false;
                    }
                    
                    if(this.info.count===this.info.total*1){
                      this.info.status = false;  
                      this.closedMsg='Closed';

                      this.EndCountdown();
                      this.end = setInterval(this.EndCountdown, 1000); // 1초마다 D-day 업데이트

                      return false;
                    }

                    

                    this.calculateCountdown();
                    
                    this.timer = setInterval(this.calculateCountdown, 1000); // 1초마다 D-day 업데이트
                    
                    if (Object.values(this.countdown).every(val => val === 0)) {
                      this.info.status = false;  
                      this.closedMsg='Deal Expired';
                      return false
                    }

                    
                    
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
    )
  },
  GetDealDetailBuyer(){
    const code = this.code;
    
    const body = {code};

    const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

    this.$http.post('/member/deal/GetDealDetailBuyer',{req}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    
                    this.list = body.list;
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
    )
  },
  calculateCountdown() {
    const now = new Date();
    const expiryDate = new Date(this.expiryDate)
    const timeDiff = expiryDate.getTime() - now.getTime();

    if (timeDiff > 0) {
      this.countdown.days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      this.countdown.hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.countdown.minutes = Math.floor(
        (timeDiff % (1000 * 60 * 60)) / (1000 * 60)
      );
      this.countdown.seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
    } else {
      // 만료된 경우
      this.countdown.days = 0;
      this.countdown.hours = 0;
      this.countdown.minutes = 0;
      this.countdown.seconds = 0;
      
      clearInterval(this.timer);
      
    }
  },
  EndCountdown(){
    const last_time = this.last_time;

    if(last_time > 0){
      this.check_end = false;
      this.end_countdown.minutes = Math.floor(last_time / 60);
      this.end_countdown.seconds = Math.floor(last_time % 60);
      this.last_time = exactMath.sub(this.last_time,1);
    }else{
      this.end_countdown.minutes = 0;
      this.end_countdown.seconds = 0;
      clearInterval(this.end);
      
      if(!this.check_end){
        this.check_end = true;
        this.GetDealDetail();
      }
      
    }
  },
  modalClose() {
  this.modalShow = false;
  },
  areAllValuesZero() {
    return Object.values(this.obj).every(value => value === 0);
  },
  CancelDeal(){
    const code = this.code;
    const body = {code};

    const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
    this.loading = true;
    this.$http.post('/member/deal/CancelDeal',{req}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                  this.$alert('Cancellation Complete.').then(()=>{
                    this.$router.push('/mypage')
                  })
                    
                }else if(res.data.code =="300"){
                    this.$alert("All tickets are sold out, unable to cancel the deal.").then(()=>{
                      this.$router.go();
                    })
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
    )
    
  },
  
  wishList(){    
    if(this.my_deal.c_wish){
        this.$pp({
            msg:'Removed from wish list',
            auto_hide:true
        })
        this.DeleteWishList()
    }else{
        this.$pp({
            msg:'Added on wish list',
            auto_hide:true
        })
        this.AddWishList();
    }
    this.my_deal.c_wish=!this.my_deal.c_wish;
  },
  AddWishList(){
      const room_code = this.code;
      const body = {room_code};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

      this.$http.post('/member/dashboard/AddWishList',{req});
  },
  GetMyBalance(){
    const symbol = this.info.coin;
    const body = {symbol};

    const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

    this.$http.post('/member/deal/GetMyBalance',{req}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    this.user_balance = body.balance;
                    
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
    )
  },
  GetCancelFee(){
    
    const body = {};

    const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

    this.$http.post('/member/deal/GetCancelFee',{req}).then(
        (res) =>  {
            if(res.status == 200){
                this.loading = false;
                if(res.data.code =="200"){
                    const e_body = res.data.body;
                    const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                    const d_res = bytes.toString(CryptoJS.enc.Utf8);
                    const body = JSON.parse(d_res)
                    this.dealer_cancel_fee =body.dealer_cancel_fee;
                    this.seller_cancel_fee =body.seller_cancel_fee;
                    
                }else if(res.data.code =="9999"){
                    this.$store.dispatch('logout').then(
                        ()=>{
                        location.href = `/signin`;
                        }
                    );
                }
            }
        }   
    )
  },
  DeleteWishList(){
      const room_code = this.code;
      const body = {room_code};
      const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

      this.$http.post('/member/dashboard/DeleteWishList',{req});
  },

  GoUserProfile(user_id,m_code){
    
    if(user_id !=''){
      if(m_code !=user_id){
        this.$router.push(`/landing/${user_id}`)
      }else{
        this.$router.push(`/mypage`)
      }
      
    }
  },
},
data(){
  return{
    loading : false,
    timer: null,
    code : this.$route.params.idx,
    expiryDate : '',
    info :{},
    winner:{},
    show : false,
    modalShow:false,
    detailModal:false,
    cancelModal:false,
    cancelCheckModal:false,
    edit_intro:false,
    refundShow:false,
    e_title : '',
    e_memo : '',
    d_nickname : '',
    d_tickets : '',
    d_total :'',
    d_uid : '',
    my_deal :{},
    deal_owner : '',
    check_end : false,
    last_time : '',
    e_memo_error : false,
    url : process.env.VUE_APP_FRONT_URL,
    // user type: user or admin -- 유저타입 스위치하면 디테일 페이지 달라짐
    userType:'user',
    closedMsg:'',
    list:[],
    countdown: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    title_error : false,
    end : null,
    end_countdown: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
    login : this.$store.state.login,
    check_admin : false,

    // cancel 수수료 임시데이터
    dealer_cancel_fee : '',
    dealer_cancel_fee_price : '',
    dealer_cancel_total : '',
    seller_cancel_fee : '',
    deal_cancel_fee_price :'',
    user_balance: '',
  }
},
props:{
  item: {
    type: Object
  }
},
computed:{
  status(){
    if(this.info.status_code =='C'){
      this.info.status = false;  
      this.closedMsg='<small>Invalid Deal<br>Deal has been cancelled by Seller</small>'
      return false;
    }

    if(this.info.count===this.info.total*1){
      // 티켓 발행량만큼 100% 달성 시
      this.info.status = false;  
      this.closedMsg='Closed';
      return false;
    }
    
    if (Object.values(this.countdown).every(val => val === 0)) {
      // 모든 값이 0인 경우. 딜 만료
      this.info.status = false;  
      this.closedMsg='Deal Expired';
      return false
    }
  }
}
}
</script>
<style lang="scss" scoped>
.deal{
color: white;
.deal-footer{
  display: flex;
  align-items: center;
  justify-content: space-around;
  position:fixed;
  bottom:0;
  left:50%;
  transform: translate(-50%);
  width: 100%;
  height: 100px;
}
.ticket{
  overflow: initial;
  width: 150px;
  &::before, &::after{
    background-color: #FFB800;
  }
}
.winner_board{
  position: relative;
  padding-top: 60px !important;
  .winner_img{
    top: -15px;
  }
  .winner_avatar{
    width: 62px !important;
    height: 62px !important;
    top: 4px;
    transform: translate(-52%, -50%) !important;
  }
  .winner_label{
    top: 35px;
  }
}
}
.edit_content{
transform: translate(-50%,15px);
border-radius: 20px 20px 0 0 !important;
font-size: 13px;
padding-top: 2em;
padding-bottom: 2em;
}
</style>