<template>
    <div>
        <SubHeader :title="'OCB SWAP'" :link="'/mypage'"/>
        <div class="content_wrap">
            <div class="bg-yellow-1 p-3 text-center rounded">
                <p class="fs-px-15 fw-bold pb-2">1 OCB = {{usdt}} USDT</p>
                <p class="fs-px-13 fw-normal">* Minimum swap quantity is {{min.toLocaleString()}} OCB</p>
                <p class="fs-px-13 fw-normal">* Completed deals can not be recalled</p>
            </div>
            <div class="border-top border-yellow-1 mt-3 py-3">
                <div class="rounded-1 h-px-48 bg-white flex-between-center">
                    <div class="px-2 flex-start-center">
                        <img src="@/assets/img/layout_icon/ticket2.svg" width="30">
                        <span class="ps-1 fw-500 fs-px-13">OCB</span>
                    </div>
                    <input type="text" class="form-control border-0 h-100 fs-px-12 text-end" placeholder="Enter quantity" v-model="ocb_quantity" @input="ChkDecimal()">
                </div>
                <p class="text-end text-burgundy-5 fs-px-13 mt-2">
                    My OCB : {{(ocb_balance).toLocaleString()}}
                </p>
            </div>
            <div class="text-center py-3">
                <img src="@/assets/img/layout_icon/arrow_alt_down_y.svg" alt="exchange" width="50">
            </div>
            <div>
                <div class="rounded-1 h-px-48 bg-white flex-between-center">
                    <div class="px-2 flex-start-center">
                        <img :src="require(`@/assets/img/icon/color/usdt.svg`)" alt="usdt" width="28">
                        <span class="ps-1 fw-500 fs-px-13">USDT</span>
                    </div>
                    <input type="text" class="form-control border-0 h-100 fs-px-12 text-end" readonly v-model="result_usdt">
                </div>
            </div>

            <div class="flex-between-center mt-3">
                <button class="py-3 border-0 btn rounded-1 bg-yellow-1 w-100 flex-center-center fw-bold" @click="GoSwap()">
                    Go Swap
                </button>
            </div>
        </div>
        <Footer></Footer>
        <Loading v-if="loading"/>
    </div>
</template>

<script>
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');

import SubHeader from '@/components/common/SubHeader.vue'
import Footer from '@/components/common/Footer.vue'
import Loading from '@/components/common/Loading.vue'
export default {
    components:{
        SubHeader, Footer,Loading
    },
    data(){
        return{
            loading:false,
            ocb_quantity: '',
            ocb_balance: '',
            result_usdt: '',
            min: '',
            usdt:'',

            // front ui
        }
    },
    mounted(){
        this.GetSwapInfo()
    },
    methods:{
        GoSwap(){
            const my_balance = this.ocb_balance;
            const result_usdt = this.result_usdt;
            const ocb_quantity = this.ocb_quantity;
            const min = this.min;

            if(ocb_quantity == ''){
                this.$pp({
                    msg: `Enter quantity`,
                    auto_hide:true
                })
                return;
            }


            const check_p = exactMath.sub(my_balance,result_usdt);
            const check_min = exactMath.sub(ocb_quantity,min);

            
            if(check_p < 0){
                this.$pp({
                    msg: `Not enough OCB`,
                    auto_hide:true
                })
                return;
            }

            if(check_min < 0){
                this.$pp({
                    msg: `Swap quantity must be at least ${this.min} OCB`,
                    auto_hide:true
                })
                return;
            }

            this.$pp({
                msg: "<h6 class='mb-1'>Are you sure you want to<br>swap to OCB?</h6><span class='text-danger small'>* Once swapped there is no going back!</span>",
                is_confirm: true,
                ok_btn_txt: 'Confirm',
                cancel_btn_txt: 'Cancel',
                add_class: 'cancel-bg-white',
                
            }).then((result)=>{
                if(result.is_ok){
                    this.SwapPoint();
                    // 
                }
            })

        },

        ChkDecimal(){
            if( !Number.isInteger( Number(this.ocb_quantity) ) ){
                this.$pp({
                    msg: `Need to enter a whole number (decimals numbers not supported)`,
                    auto_hide:true
                })
                this.ocb_quantity = Math.floor( Number(this.ocb_quantity) );
                
            }

            this.result_usdt =exactMath.mul(this.ocb_quantity,this.usdt);

        },
        SwapPoint(){
            const ocb_quantity = this.ocb_quantity;
            const body = {ocb_quantity};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.loading = true;
            this.$http.post('/member/mypage/SwapPoint',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$router.push("/transaction/history")
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        GetSwapInfo(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/mypage/GetSwapInfo',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            const info = body.info
                            this.ocb_balance = info.balance;
                            this.usdt = info.usdt;
                            this.min = info.min;
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        }
        // UpdateFormattedValue() {
        //     if( this.ocb_quantity != null ){
        //         this.ocb_quantity = this.ocb_quantity.replace(/[^0-9.]/g, "");
        //         const numberValue = this.ocb_quantity.replace(/,/g, "");
                
        //         if (!isNaN(numberValue)) {
        //             const formattedNumber = Number(numberValue).toLocaleString();
        //             this.ocb_quantity = formattedNumber;
        //         }

        //     }
        // }
    }

}
</script>