<template>
    <b-modal v-model="isShown" centered hideHeader @hidden="$emit('close')">

        <div class="step1" v-if="step===1">
            <h6 class="text-center mb-2 py-2">REFUND PURCHASE</h6>
            <div class="flex-between-center fs-px-11"><small class="small text-muted">My Total Purchase</small>
            <b>{{ buyTF.total }}</b></div>
            <small class="small text-muted fs-px-11">Purchase Cancellation Tickets</small>
            <div class="input-group ticket_count my-2">
                <span class="rounded-0 input-group-text" :class="{'active':quantity>=limit}" @click="decreaseQuantity"><i class="mx-auto far fa-minus"></i></span>
                <input type="number" class="form-control border-0 bg-yellow-5 text-center fw-bold" v-model="quantity" @input="checkInput">
                <span class="rounded-0 input-group-text" :class="{'active':quantity<limit}" @click="increaseQuantity"><i class="mx-auto far fa-plus"></i></span>
            </div>

            <div class="flex-column mb-3">
                <small class="small fw-light fs-px-11 text-muted">Select ticket you wish to refund</small>
                <ul class="ticket_num_box">
                    <li class="badge text-black" :class="{'bg-yellow-2':selectedTickets.includes(item),'bg-yellow-4':!selectedTickets.includes(item) }" v-for="(item, index) in buyTF.tickets" :key="index" @click="toggleSelection(item)">{{item}}</li>
                </ul>
            </div>
            <div class="fs-px-12">
                <div class="flex-between-center mb-3">
                    <span>Purchase Cancellation Refund Amount</span>
                    <span class="fw-bold">{{ RefundCheckAmount(info.d_price,selectedTickets.length) }} {{buyTF.deal_coin}}</span>
                </div>
                <div class="flex-between-center">
                    <span>Purchase Cancellation Fee (2.5%)</span>
                    <!-- 수수료 임의로 2% -->
                    <span class="fw-bold">{{ RefundCheckFee(info.d_price,selectedTickets.length) }} {{buyTF.deal_coin}}</span>
                </div>
            </div>
            <hr class="border-yellow-1">
            
            <div class="flex-between-center fs-px-12">
                <span>Total Refund Amount</span>
                <span class="fw-bold">{{ RefundCheckAmountFee(info.d_price,selectedTickets.length) }} {{buyTF.deal_coin}}</span>
            </div>


        </div>
        <div class="step2" v-if="step===2">
            <div class="fs-px-12">
                <p class="pt-2 text-center">*Once refund request submitted,<br>
the request may not be retreated.<br>
Are you sure you want to make the refund?</p>
            </div>
        </div>
        <template #modal-footer>
            <div class="w-100">
            <div v-if="step===1" class="d-flex">
                <button class="py-3 fs-px-12 btn btn-transparent w-50" @click="$emit('close'); step=1">Retreat</button>
                <button class="py-3 fs-px-12 fw-bold btn btn-transparent bg-yellow-1 w-50" @click="confirm">CONFIRM</button>
            </div>
            <div v-else class="d-flex gap-2">
                <button class="py-3 fs-px-12 btn bg-gray-100 w-50" @click="$emit('close'); step=1">NO</button>
                <button class="py-3 fs-px-12 fw-bold btn btn-transparent bg-yellow-1 w-50" @click="ConfirmReFund()">YES</button>
            </div>
            </div>
        </template>
    </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');
export default {
    components: {
        BModal
    },
    data(){
        return{
            quantity:1,
            limit:99,
            step:1,
            selectedTickets:[],

            // fron ui
            isShown: this.show
        }
    },
    methods:{
        RefundCheckAmount(a,b){
            const info = exactMath.mul(a,b)
            return info
        },
        RefundCheckFee(a,b){
            const info = exactMath.mul(a,b)
            const fee = 0.025;
            const per = exactMath.mul(info,fee);

            return per
        },
        RefundCheckAmountFee(a,b){
            const info = exactMath.mul(a,b)
            const fee = 0.025;
            const per = exactMath.mul(info,fee);

            const fee_price = exactMath.sub(info,per);
            return fee_price
        },
        ConfirmReFund(){
            const code = this.info.code;
            const list = this.selectedTickets;
            const body = {code,list};

            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/deal/ReFund',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        if(res.data.code =="200"){
                            this.$emit('close'); 
                            this.$router.push('/mypage')
                            this.$pp({
                                msg: 'Refund Complete', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
            
        },
        toggleSelection(ticket) {
            if (this.selectedTickets.includes(ticket)) {
                this.selectedTickets = this.selectedTickets.filter((item) => item !== ticket);
            } else {
                this.selectedTickets.push(ticket);
            }
            this.updateQuantity();
        },
        decreaseQuantity() {
        if (this.quantity > 1) {
            this.quantity--;
            this.checkLimit();
            this.updateSelectedTickets(0);
        }
        },

        increaseQuantity() {
        if (this.quantity < this.buyTF.tickets.length) {
            this.quantity++;
            this.checkLimit();
            this.updateSelectedTickets(1);
        }
        },
        checkInput(){
            this.checkLimit()
            const selectedCount = Math.min(this.quantity, this.buyTF.tickets.length);
            this.selectedTickets = this.buyTF.tickets.slice(0, selectedCount);
        },
        checkLimit(){
            const priceData = this.info.d_price;
            if (this.quantity === 0) {
                this.$pp({
                    msg: 'You need to enter at least 1 ticket.', 
                    is_confirm: false, 
                    auto_hide:true
                }).then( (result)=>{
                })
                this.quantity=1;
            }
            if(this.quantity>this.buyTF.quantity){
                this.$pp({
                    msg: 'Exceeding purchased ticket quantity', 
                    is_confirm: false, 
                    auto_hide:true
                }).then( (result)=>{
                })
                this.quantity = parseInt(Math.floor(this.my_balance / priceData));
            }

            if(this.perMax){
                // 최대구매수량 제한이 있을때
                if(this.quantity > this.perMax){
                    this.$pp({
                        msg: 'Your purchase exceeds purchase limit', 
                        is_confirm: false, 
                        auto_hide:true
                    }).then( (result)=>{
                    })
                    this.quantity = this.perMax;
                }
            }else{
                // 없을때 최대 티켓한도
                if(this.quantity > this.limit){
                    this.$pp({
                        msg: 'Your purchase exceeds total number of tickets', 
                        is_confirm: false, 
                        auto_hide:true
                    }).then( (result)=>{
                    })
                    this.quantity = this.limit;
                }
            }

        },
        updateSelectedTickets(type) {
            let sortedTickets = []
            if(type){
                sortedTickets = this.buyTF.tickets.sort((a, b) => a - b);

            }else{
                sortedTickets = this.selectedTickets.sort((a, b) => a - b);
            }
            this.selectedTickets = sortedTickets.slice(0, this.quantity);        
        },
        updateQuantity() {
            this.quantity = this.selectedTickets.length;
        },
        confirm(){
            if(this.quantity==0){
                this.$pp({
                    msg: 'You need to enter at least 1 ticket.', 
                    is_confirm: false, 
                    auto_hide:true
                })
                return false;
            }else if(this.selectedTickets.length<this.quantity){
                this.$pp({
                    msg:'Select additional ticket you wish to refund',
                    auto_hide:true
                })
            }else if(this.selectedTickets.length>this.quantity){
                this.$pp({
                    msg:'Refund quantity does not match the selected ticket amount.',
                    auto_hide:true
                })
            }else{
                this.step=2;
            }
        }
    },
    computed:{
        // cancel_fee(){
        //     return ((this.info.d_price*this.selectedTickets.length)*0.025).toFixed(4)
        // }
    },
    watch:{
        show(){
            this.isShown = this.show;
        }
    },
    mounted(){
        this.selectedTickets.push(this.buyTF.tickets[0])
    },
    props:{
        show:{
            type:Boolean,
            required: true
        },
        
        buyTF:{
            type:Object,
            required:false,
            default: function(){
                return{
                    status:false,
                }
            }
        },
        info:{
            type:Object,
            default: function(){
                return{
                    price:null,
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.ticket_count{
    span.input-group-text{
        width: 36px;
        padding: 0;
        background-color: #EDEDED;
        border: 3px solid #aaa;
        color: #aaa;
    }
    span.input-group-text.active{
        background-color: #FFB800;
        border: 3px solid #9A0058;
        color: #9A0058;
    }
}
</style>