<template>
    <div>
        <SubHeader :title="`${nickname}'s Deal`" />
        <div class="content_wrap">
            <div class="flex-center-center flex-column mt-4">
                <img src="@/assets/img/user-icon.svg" alt="profile" width="90" v-if="profile_img == ''">
                <img :src="profile_img" alt="profile" width="90" height="90" class="rounded-circle object-fit-cover" v-else>

                <div class="txt_box w-px-200 mt-2">
                    <ul>
                        <li class="flex-between-center mb-2">
                            <span class="text-burgundy-4">Nickname</span>
                            <span class="fw-bold text-white">{{nickname}}</span>
                        </li>
                        <li class="flex-between-center">
                            <span class="text-burgundy-4">UID No</span>
                            <span class="fw-bold text-white">{{uid}}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="py-3 mt-4">
                <ul class="pt-0 d-flex text-center fw-400">
                    <li class="rounded-start py-3 w-50 rounded-bottom-0"
                        :class="{'bg-yellow-1 fw-bold': my_deals_tab==0 , 'bg-yellow-4 text-gray-500' : my_deals_tab !=0}"
                        @click="my_deals_tab = 0"
                    >BUY</li>

                    <li class="rounded-end py-3 w-50 rounded-bottom-0" 
                        :class="{'bg-yellow-1 fw-bold': my_deals_tab==1 , 'bg-yellow-4 text-gray-500' : my_deals_tab !=1}
                        "
                        @click="my_deals_tab = 1"    
                    >SELL</li>
                </ul>
                <div class="rounded-1 bg-white rounded-top-0" v-show="my_deals_tab==0">
                    <ul class="last-border-0">
                        <li class="border-bottom border-yellow-1 mx-3">
                            <div @click="wishlist_count != 0 ? $router.push(`/user/buy/wish-list/${user_code}`) : ''" class="flex-between-center py-3">
                                <span>Wishlist</span>
                                <div class="flex-start-center">
                                    <span class="lh-1">{{wishlist_count}}</span>
                                    <i class="fas fa-chevron-right text-yellow-1 ms-2"></i>
                                </div>
                            </div>
                        </li>
                        <li class="border-bottom border-yellow-1 mx-3">
                            <div @click="participating_deals_count != 0 ? $router.push(`/user/buy/participate/${user_code}`) : ''" class="flex-between-center py-3">
                                <span>Participating Deals</span>
                                <div class="flex-start-center">
                                    <span class="lh-1">{{participating_deals_count}}</span>
                                    <i class="fas fa-chevron-right text-yellow-1 ms-2"></i>
                                </div>
                            </div>
                        </li>
                        <li class="border-bottom border-yellow-1 mx-3">
                            <div @click="won_deals_count != 0 ? $router.push(`/user/buy/won/${user_code}`) : ''" class="flex-between-center py-3">
                                <span>Won deals</span>
                                <div class="flex-start-center">
                                    <span class="lh-1">{{won_deals_count}}</span>
                                    <i class="fas fa-chevron-right text-yellow-1 ms-2"></i>
                                </div>
                            </div>
                        </li>
                        <li class="border-bottom border-yellow-1 mx-3">
                            <div @click="closed_deals_count != 0 ? $router.push(`/user/buy/closed/${user_code}`) : ''" class="flex-between-center py-3">
                                <span>Closed Deals</span>
                                <div class="flex-start-center">
                                    <span class="lh-1">{{closed_deals_count}}</span>
                                    <i class="fas fa-chevron-right text-yellow-1 ms-2"></i>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="rounded-1 bg-white rounded-top-0" v-show="my_deals_tab==1">
                    <ul class="last-border-0">
                        <li class="border-bottom border-yellow-1 mx-3">
                            <div @click="deal_in_progress_count != 0 ? $router.push(`/user/sell/sale/${user_code}`) : ''" class="flex-between-center py-3">
                                <span>Deal in progress</span>
                                <div class="flex-start-center">
                                    <span class="lh-1">{{deal_in_progress_count}}</span>
                                    <i class="fas fa-chevron-right text-yellow-1 ms-2"></i>
                                </div>
                            </div>
                        </li>
                        <li class="border-bottom border-yellow-1 mx-3">
                            <div @click="cancelled_deals_count != 0 ? $router.push(`/user/sell/cancel/${user_code}`) : ''" class="flex-between-center py-3">
                                <span>Seller Cancelled</span>
                                <div class="flex-start-center">
                                    <span class="lh-1">{{cancelled_deals_count}}</span>
                                    <i class="fas fa-chevron-right text-yellow-1 ms-2"></i>
                                </div>
                            </div>
                        </li>
                        <li class="border-bottom border-yellow-1 mx-3">
                            <div @click="complete_deals_count != 0 ? $router.push(`/user/sell/soldout/${user_code}`) : ''" class="flex-between-center py-3">
                                <span>Closed deals</span>
                                <div class="flex-start-center">
                                    <span class="lh-1">{{complete_deals_count}}</span>
                                    <i class="fas fa-chevron-right text-yellow-1 ms-2"></i>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Footer from '@/components/common/Footer.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        SubHeader, Footer
    },
    data(){
        return{
            nickname: '',
            uid: '',
            profile_img: '',

            wishlist_count: 0,
            participating_deals_count: 0,
            won_deals_count: 0,
            closed_deals_count: 0,

            deal_in_progress_count: 0,
            cancelled_deals_count: 0,
            complete_deals_count: 0,

            t_symbol : '',

            ocb_point: 0,

            // front ui
            coin_trannsaction_pp: false,
            referal_OCB_point_pp: false,
            my_deals_tab: 0,
            user_code : this.$route.params.code
        }
    },
    mounted(){
        this.GetUserInfo()
        this.GetHistoryList();
    },
    methods:{
        GetUserInfo(){
            const code = this.user_code;
            const body = {code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/user/GetUserInfo',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.profile_img = body.info.profile;
                            this.uid = body.info.code;
                            this.nickname = body.info.nick_name;
                            
                        }
                    }
                }   
            )
        },
        GetHistoryList(){
            const code = this.user_code
            const body = {code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();



            this.$http.post('/member/user/GetHistoryList',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            const info = body.info
                            this.wishlist_count = info.wish_count;
                            this.won_deals_count = info.win_count;
                            this.participating_deals_count = info.participate_count;
                            this.closed_deals_count = info.close_count;

                            this.deal_in_progress_count = info.deal_in_progress_count;
                            this.cancelled_deals_count = info.cancelled_deals_count;
                            this.complete_deals_count = info.complete_deals_count;
                            
                        }
                    }
                }   
            )
        },
    }

}
</script>